import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { CardCollapse, Typography } from '../../components/library'


const profile = {
    role: 'Child',
    name: 'James',
    avatar: 'panda',
    id: 'b7dd4698-d0a5-11eb-b8bc-0242ac130003'
}

function cardTurn() {
    return (
        <LibraryWrapper>
            <CardCollapse profile={profile} title="James’ Responses (4)">
                <Typography variant="body1" align="left">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
            </CardCollapse>
            <hr />
            <CardCollapse title="symptoms" number={3}>
                <Typography variant="body1" align="left">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
                    unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam {' '}
                    <strong>dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam</strong>.
                </Typography>
            </CardCollapse>
        </LibraryWrapper>
    )
}

export default cardTurn
